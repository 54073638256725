$(document).ready(function() {
	// $('.new-gallery').lightGallery({
	// 	thumbnail: true,
	// 	animateThumb: false,
	// 	showThumbByDefault: false,
	// 	selector: '.gallery-item'
	// });
	$('.projects').lightGallery({
		thumbnail: true,
		animateThumb: false,
		showThumbByDefault: false,
		selector: '.gallery-item'
	});
});